.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position: relative;
  background: white;
  width: 500px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-close {
  font-size: 16pt;
  margin-right: 5px;
  cursor: pointer;
}
.modal-body {
  height: 90%;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-top: 0px;
  position: static !important;  
}

.close_btn{
    position: absolute;
    right: 47px;
    top: 29px;
    z-index: 10;
}