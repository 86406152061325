#chat-message-input {
  margin-right: -5px;
}
.chat-messages {
  height: calc(100vh - 138px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 0.5s;
}

.chattemp{
  width: fit-content;
  position: relative;
}
.chat-message-right-bg{
    background-color:rgb(0 128 38 / 14%);
}
.chat-message-left-bg{
    background-color: #f8f9fa;
}

.chat-message-left {
    display: flex;
    justify-content: start;
}
.chat-message-right {
    display: flex;
    justify-content: end;
}

/* typing bubble */
.chat-bubble {
  background-color: #fff3cd;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1s infinite ease-in-out;
  background-color: #fff0c0;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #ffc003;
  }
  28% {
    transform: translateY(-7px);
    background-color: #e4bf52;
  }
  44% {
    transform: translateY(0px);
    background-color: #dac587;
  }
}


.file-input{
    /* opacity: 0;
    position: absolute;
    right: 62px;
    top: 3px;
    width: 30px; */
    display: none;
} 


.imageupdate{
    width: 30px;
    border-top: 1px solid silver;
    border-bottom:1px solid silver;
    cursor: pointer;
}
.templates{
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
    padding-right: 10px;
    cursor: pointer;
}

.eachtempData{
  border-bottom: 1px solid silver;
  cursor: pointer;
}
.head{
  position: fixed;
  background-color: white;
  width: 86%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.chatnavbar{
  background-color: #80808014;
}

.submitbtn{
    position: absolute;
    bottom: 5px;
    right: 41px;
}
.lngtraslater{
  position: relative;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}
.langicon{
  z-index: 3;  
  padding-right: 10px;
  cursor: pointer;
}
.langpopup{
  position: absolute;
  bottom: 33px;
  right: 0;
  background-color: white;
  border: 1px solid silver;
  border-radius: 5px;
}

.langpopup::before {
  content: '';
  position: absolute;
  bottom: -4px; /* Adjust the position as needed */
  right: 16px; /* Adjust the position as needed to align with the icon */
  width: 0;
  height: 0;
  border-right: 8px solid black;
  border-top: 8px solid white; /* Match the background color of .langpopup */
  border-bottom: none;
  transform: rotate(45deg);
}

.langlist{
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid silver;
}
.langUl{
  list-style: none;
  width: 120px;
  padding: 0;
  margin-bottom: 0px;
  background-color: #c0c0c066;
}
.eachmessage{
  max-width: 300px;
  overflow-wrap: break-word;
}
.datediv{
  font-size: 11px;
  align-self: center;
  margin: 17px 0px;
  border-radius: 5px;
  padding: 1px 10px;
  background: #f4f4174a;
}



