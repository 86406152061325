.user-list-container {
  overflow: auto;
  overflow-x: hidden;
  height: 80vh;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
.chat-online {
  color: #34ce57;
}
.chat-offline {
  color: #e4606d;
}
.list-group-item {
  padding: 0.4rem 1.25rem;
  font-size: 10pt;
}

/* @media (max-width:900px){
  .list-group-item {
    height: 70px !important;
  }
} */


.list-group-item-action:focus,
.list-group-item-action:hover,
.active-chat {
  background-color: #fff3cd;
}

.whatsappclass{
  background-color: yellow;
  border-radius:5px ;
  padding: 1px 10px;

  width: fit-content;
}
.navbar{
  height: 65px;
  background-color: #80808014;
}

#fruits{
  width: fit-content;
  height: 24px;
  border-radius: 4px;
}

.logoutbtn{
  position: fixed;
  top: 10px;
  right: 25px;
  width: fit-content;
  z-index: 1000;
}
.qr{
  padding-top: 100px;
  
}
